<!--
 * @Descripttion: 工具详情页
 * @version: 1.0
 * @Author: Niklaus
 * @Date: 2021-11-08 17:30:50
-->
<template>
  <wk-header ref="header" :currentIndex="3" ></wk-header>
  <el-main>
    <el-row>
      <el-col :span="18" :offset="3">
        <!-- 头部信息 -->
        <div class="tool-detail-header mt30">
          <div class="flex">
            <img :src="toolsObj.propagatePic" class="img-normal" />
            <div class="inline pl20">
              <h2>{{toolsObj.toolName}}</h2>
              <p class="mt10">
                <label v-for="industry in toolsObj.industrys" :key="industry" class="root-small-acti inline mr20">{{industry.labelName}}</label>
              </p>
              <p class="mt10">
                <label class="inline mr20" v-for="auth in toolsObj.auths" :key="auth">
                  <img :src="auth.labelIconUrl" />
                </label>
              </p>
            </div>
          </div>
          <div class="operating-btn">
            <el-button v-if="toolsObj.collectionFlag&&toolsObj.collectionFlag=='1'" type="success" class="block" @click="removeCollect()">已收藏</el-button>
            <el-button v-else type="info" class="block" @click="collect()">去收藏</el-button>
            <el-button type="primary" class="block" @click="handleView(toolsObj.sourceUrl)">访问官网</el-button>
          </div>
        </div>
        <!-- 统计信息 -->
        <!-- <div>
          <el-row  class="mt20">
            <el-col :span="8" class="center">
              <h3 class="flex-center">{{toolsObj.score}} <star-filled style="width:.32rem;height:.32rem; color:#FFC300;vertical:middle" /></h3>
              <p class="count-desc">推荐指数</p>
            </el-col>
            <el-col :span="8" class="center">
              <h3>{{toolsObj.downloadNums || 0}}</h3>
              <p class="count-desc">下载</p>
            </el-col>
            <el-col :span="8" class="center">
              <h3>{{toolsObj.lookNums || 0}}</h3>
              <p class="count-desc">访问量</p>
            </el-col>
          </el-row>
        </div> -->



        <swiper
          :slidesPerView="3"
          :spaceBetween="20"
          :navigation="true"
          :loop="false"
          class="swiper mt20"
        >
          <swiper-slide v-for="(item,index) in tttt" :key="item">
            <div class="swiper_item"  >
              <img class="w100" :src="item"  @click.stop="showDialogSwiper(index)">
            </div>
          </swiper-slide>
        </swiper>


        <!-- 应用介绍 -->
        <div class="mt30">
          <h2>应用介绍</h2>
          <p class="mt20 l5">{{toolsObj.introduce}}</p>
        </div>

        <!-- 应用信息 -->
        <div class="mt30">
          <h2>应用信息</h2>
          <el-row class="application">
            <el-col :span="10" class="flex-between">
              <p>资费</p>
              <p>{{toolsObj.source}}</p>
            </el-col>
            <el-col :span="10" :offset="4" class="flex-between">
              <p>分类</p>
              <p>{{toolsObj.sortStr}}</p>
            </el-col>

            <el-col :span="10" class="flex-between">
              <p>应用行业</p>
              <p>{{toolsObj.industryStr}}</p>
            </el-col>
            <el-col :span="10" :offset="4" class="flex-between">
              <p>开发商</p>
              <p>{{toolsObj.developers}}</p>
            </el-col>
            <template v-if="toolsObj.version">
              <el-col :span="10" class="flex-between">
                <p>版本</p>
                <p>{{toolsObj.version}}</p>
              </el-col>
              <el-col :span="10" :offset="4" class="flex-between" v-if="toolsObj.lastUpdateDatetime">
                <p>更新时间</p>
                <p>{{toolsObj.lastUpdateDatetime}}</p>
              </el-col>              
            </template>
            <template v-else>
              <el-col :span="10" class="flex-between" v-if="toolsObj.lastUpdateDatetime">
                <p>更新时间</p>
                <p>{{toolsObj.lastUpdateDatetime}}</p>
              </el-col>
            </template>         
          </el-row>
        </div>

        <!-- 推荐评分 -->
        <div class="mt30">
          <h2>推荐指数</h2>
          <h2 class="score mt20">{{toolsObj.score}}</h2>
          <el-rate v-model="toolsObj.score" disabled-void-color="#b1b5bd" disabled allow-half />
        </div>

        <!-- 应用案例 -->
        <div class="mt30">
          <h2>应用案例</h2>
            <el-row :gutter="20" class="mt20">
              <el-col :span="4" class="center" v-for="item in toolsObj.caseCompanys" :key="item">
                <img :src="item.thumbPic" alt="">
              </el-col>
            </el-row>
        </div>
      </el-col>
      <el-dialog
        v-model="show"
        :width="swiperWidth"
        custom-class="swiperDialog"
      >
        <el-carousel
          :initial-index="activeIndex"
          :loop="false"
          :autoplay="false"
          :height="swiperHeight + 'px'"
          ref="swiperRef"
        >
          <el-carousel-item v-for="item in tttt" :key="item">
            <img :src="item" class="w100" alt="">
          </el-carousel-item>
        </el-carousel>
      </el-dialog>
    </el-row>
  </el-main>
  <wk-footer></wk-footer>
</template>

<script>
import WkHeader  from '@/components/WKHeader'
import WkFooter from '@/components/WKFooter'
import {ElRate,ElCarousel, ElCarouselItem, ElDialog,ElMessage} from 'element-plus'
import {getAction,postAction} from '@/utils/http'
import axios from  'axios'
// 导入swiper中vue组件
import { Swiper, SwiperSlide } from 'swiper/vue'
// 导入swiper模块
import SwiperCore, {
  // 设置底部小圆点
  Pagination,
  // 设置两边的箭头
  Navigation,
  // 动画效果
  EffectCoverflow,
  // 自动播放
  Autoplay
} from 'swiper';
SwiperCore.use([Pagination, Navigation, EffectCoverflow, Autoplay])
// 导入swiper的样式，注意此处导入的样式
import "swiper/swiper-bundle.css"
export default {

  name: 'ToolDetail',
  components: {
    WkHeader,
    WkFooter,
    Swiper,
    SwiperSlide,
    ElRate,
    ElDialog,
    ElCarousel,
    ElCarouselItem

  },

  data() {
    return {
      toolsObj:{
        toolName:'',
        desc:'',
        introduce:'',
        sourceUrl:'',
        propagatePic:'',
        source:'',
        version:'',
        size:'',
        developers:'',
        chargeFlag:'',
        chargeAmount_range:'',
        score:0,
        downloadNums:1,
        lookNums:1,
        industrys:[],
        sorts:[],
        auths:[],
        caseCompanys:[],
        mainPicList:[],
        sortStr:'',
      },
      tttt: {},
      urls:{
        getToolsDetail:'/wbpt/tools/detail/selectWbptToolVo'
      },
      show:false,
      activeIndex: 0,
      swiperHeight: 0,
      heightWithRatio:0,
      swiperWidth: 1000
    }
  },
  beforeMount() {
    document.body.scrollTop = 0;
			document.documentElement.scrollTop = 0;
    if(this.$route.query.toolId){
      this.toolsObj.id = this.$route.query.toolId;
    }
  },
	mounted(){
    //查询工具详情
    this.getToolsDetail();
		//阅读数加1
		this.addToolsReadnum(this.toolsObj.id)
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.getSwiperHeight, true)
  },

  methods:{

    /**
     * @Description: 计算大屏轮播的高及自适应高度
     * @Author: Niklaus
     * @Date: 2021-11-22 09:31:30
     */
    initSwiperHeight(url) {
      let that = this
      if(this.heightWithRatio === 0) {
        let image = new Image()
        image.src = url
        image.onload= () => {
          that.heightWithRatio = image.height /  image.width
          that.getSwiperHeight()
        }
      }
      window.addEventListener('resize', that.getSwiperHeight,true)
    },
    /**
     * @Description: 显示大屏轮播
     * @Author: Niklaus
     * @Date: 2021-11-22 09:31:06
     * @param {*} index
     */
    showDialogSwiper(index) {
      this.activeIndex = index
      this.show = true
      this.$nextTick(() =>{
        this.$refs.swiperRef.setActiveItem(index)
      })
    },

    /**
     * @Description: 获取大屏轮播的高度
     * @Author: Niklaus
     * @Date: 2021-11-22 14:59:41
     */
    getSwiperHeight() {
      if(this.heightWithRatio > 1) {
        this.swiperWidth =  500
      }
      this.swiperHeight = this.swiperWidth * this.heightWithRatio
    },

    //查询工具详情
    getToolsDetail(){
      let userId = '';
      if(this.$ls.get("wikeHome_current_userInfo")){
        userId = this.$ls.get("wikeHome_current_userInfo").id;
      }
      let params ={
        id: this.toolsObj.id,
        userId:userId
      }
      getAction(this.urls.getToolsDetail,params).then(res=>{
        if(res.success){
          this.toolsObj = res.result;
          this.initSwiperHeight(res.result.mainPicList[0])
          this.$nextTick( () => {
            this.tttt =  res.result.mainPicList
          })
          let sortStr = "";
          let industryStr = "";
          console.log(res.result.sorts);
          if(res.result.sorts&&res.result.sorts.length>0){
            res.result.sorts.forEach(element => {
              sortStr = sortStr + element.labelName+"  ";
            });
          }
          if(res.result.industrys&&res.result.industrys.length>0){
            res.result.industrys.forEach(element => {
              industryStr = industryStr + element.labelName+"  ";
            });
          }
          this.toolsObj.sortStr = sortStr;
          this.toolsObj.industryStr = industryStr;
        }else{
          console.log('获取工具详情失败！');
          ElMessage({
            message: res.message,
            type: 'error',
          });
        }
      }).catch(err=>{
          console.log(err);
      });
    },
    //查看链接
    handleView(url){
      if(url){
        window.open(url);
      }
    },
		//收藏
		collect:function(){
			//userId,toolsId
			let postData = {}
			// 测试环境-----用户id登录后获取
			postData.userId = ""
      if(this.$ls.get("wikeHome_current_userInfo")){
        postData.userId = this.$ls.get("wikeHome_current_userInfo").id;
        //////////////////////////////
        postData.naturalKey = this.toolsObj.id
        postAction("/wbpt/collection/tools/collect",postData).then(res=>{
          if(res.retCode&&res.retCode=='0'){
            ElMessage({
              message: '收藏成功',
              type: 'success',
            });
            this.toolsObj.collectionFlag = '1';
          }else{
            ElMessage({
              message: '收藏失败,请稍后再试！',
              type: 'error',
            });
          }
        }).catch(()=>{
            ElMessage({
              message: '收藏异常，请稍后再试！',
              type: 'error',
            });
        });
      }else{
        ElMessage({
          message: '请先登录，再点击收藏',
          type: 'info',
        });
        this.$refs.header.onShowDialogLogin();
      }
		},
    //阅读数加1
		addToolsReadnum:function(id){
      let ip = "";
      if(sessionStorage.getItem('ip')){
        ip = sessionStorage.getItem('ip')
      }           
      let userId = '';
      if(this.$ls.get("wikeHome_current_userInfo")){
        userId = this.$ls.get("wikeHome_current_userInfo").id;
      }
      let obj={
        toolsId:id,
        userId:userId,
        ip:ip
      }
      getAction("/wbpt/tools/home/updateToolsReadnumWithId",obj)     

		},
		//取消收藏
		removeCollect(){
			let postData = {}
			postData.userId = ""
      if(this.$ls.get("wikeHome_current_userInfo")){
        postData.userId = this.$ls.get("wikeHome_current_userInfo").id;
        postData.key = this.toolsObj.id
        postAction("/wbpt/collection/tools/removeCollectNew",postData).then(res=>{
          if(res.success){
            ElMessage({
              message: '取消收藏成功',
              type: 'success',
            });
            this.toolsObj.collectionFlag = '0';
          }else{
            ElMessage({
              message: '取消收藏失败,请稍后再试！',
              type: 'error',
            });
          }
        }).catch(()=>{
            ElMessage({
              message: '取消收藏异常，请稍后再试！',
              type: 'error',
            });
        });
      }else{
        ElMessage({
          message: '请先登录，再点击取消收藏',
          type: 'info',
        });
        this.$refs.header.onShowDialogLogin();
      }
		},
  }

}
</script>

<style scoped>
  .tool-detail-header {
    display: flex;
    display: -webkit-flex;
    justify-content: space-between;
  }
  .operating-btn {
    text-align: right;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .el-button+.el-button {
    margin-left: 0;
  }
  .count-desc {
    font-size: .173333rem;
    color: #000;
    margin-top: .08rem;
  }

  .el-carousel__item h3 {
  color: #475669;
  font-size: .186667rem;
  opacity: 0.75;
  line-height: 2.666667rem;
  margin: 0;
  text-align: center;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
.application {
  margin-top: 15px;
}
.application .flex-between {
  padding: 15px 5px;
  border-bottom: .013333rem solid #eee;
}
.application .flex-between:hover {
  background: #f4f4f5;
  transition: bacground .3s linear;
}
.score {
  color: #1E87FF;
  font-size: 2.5em;
  font-weight: bold;
  width: 2.3em;
  text-align: center;
}
.el-dialog__header {
  height: 0 !important;
}
.img-normal {
  border: 1px solid #ededed;
}
</style>
